import React, { createContext, useState, useEffect, useContext } from 'react'
import { getCountryFromIP } from '../utils'
import { COUNTRIES } from '../config/App'
import { config } from '../config/Localization'

export const LocalizationContext = createContext()

export const LocalizationProvider = ({ children }) => {
  const [userCountry, setUserCountry] = useState(null)
  const [loading, setLoading] = useState(true)

  const validateCountry = country => {
    return COUNTRIES.includes(country) ? country : 'uy'
  }

  const fetchUserCountry = async () => {
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.has('country')) {
        const country = params.get('country')
        return setUserCountry(validateCountry(country))
      }
      const storedCountry = localStorage.getItem('storedUserCountry')
      if (storedCountry) {
        setUserCountry(validateCountry(storedCountry))
      } else {
        const countryFromIP = await getCountryFromIP()
        const validCountry = validateCountry(countryFromIP)
        localStorage.setItem('userCountry', validCountry)
        setUserCountry(validCountry)
      }
    } catch (error) {
      const country = localStorage.getItem('userCountry')
      setUserCountry(validateCountry(country))
    } finally {
      setLoading(false)
    }
  }

  const changeUserCountry = async newCountry => {
    const validCountry = validateCountry(newCountry)
    localStorage.setItem('storedUserCountry', validCountry)
    setUserCountry(validCountry)
  }

  useEffect(() => {
    fetchUserCountry()
  }, [])

  return (
    <LocalizationContext.Provider
      value={{ userCountry, loading, changeUserCountry, config: config[userCountry] }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalizationContext = () => {
  return useContext(LocalizationContext)
}
