import { useEffect, useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import SearchError from '../../commons/SearchError'
import LoadingIndicator from '../../commons/LoadingIndicator'

export default ({ lobbiesRequest, busesRequest, handleSuccess, handleBlockedRoute, children }) => {
  const {
    loading: loadingLobbies,
    responseOk: responseOkLobbies,
    response: responseLobbies,
    data: dataLobbies
  } = lobbiesRequest
  const {
    loading: loadingBuses,
    responseOk: responseOkBuses,
    response: responseBuses
  } = busesRequest
  const [dataReady, setDataReady] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!loadingLobbies && !loadingBuses) {
      if (responseOkLobbies && responseOkBuses) {
        responseBuses.json().then(dataBuses => {
          handleSuccess(dataLobbies, dataBuses)
        })
      } else if (responseOkLobbies) {
        handleSuccess(dataLobbies, [])
      } else if (responseLobbies.status === 404) {
        handleBlockedRoute(dataLobbies)
      } else {
        setError(true)
        Mixpanel.track('Request error', { Error: dataLobbies })
      }
      setTimeout(() => {
        setDataReady(true)
      }, 1000)
    }
  }, [loadingLobbies, loadingBuses])

  if (!error && dataReady) return children

  return (
    <>
      {loadingLobbies || loadingBuses || !dataReady ? (
        <div className='flex h-[80%] w-full justify-center items-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <SearchError />
      )}
    </>
  )
}
