import { useEffect } from 'react'
import { useFetchEditUser } from '../../hooks'
import Button from '../../commons/Button'
import apiConfig from '../../config/Api'
import SearchError from '../../commons/SearchError'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useAuth } from '../../hooks/'
import { Mixpanel } from '../../Mixpanel'
import LoadingIndicator from '../../commons/LoadingIndicator'

const SendData = ({ data, onSubmit, onError }) => {
  var { user } = useAuth()
  const { loading, response } = useFetchEditUser(`${apiConfig.uri}/users/me`, {
    method: 'PATCH',
    body: JSON.stringify({
      first_name: data.name.trim(),
      last_name: data.lastName.trim(),
      phone_number: data.phoneNumber.trim(),
      birthdate: data.selectedDate.toISOString(),
      gender: data.gender.trim(),
      biography: data.aboutMe.trim(),
      preferences: data.preferences
    })
  })

  useEffect(() => {
    if (response.ok) {
      Mixpanel.track('Registrado correctamente', {
        Usuario: user
      })
    } else if (response.ok == false) {
      Mixpanel.track('Error al registrar', {
        Usuario: user,
        Error: response.status
      })
    }
  }, [loading])

  return (
    <div className='h-full flex flex-col justify-center text-center'>
      {loading ? (
        <div className='flex flex-col items-center'>
          <LoadingIndicator />
        </div>
      ) : response.ok ? (
        <div>
          <p className='mb-6 text-lg'>¡Información cargada con éxito!</p>
          <Button name='Aceptar' onClick={onSubmit} />
        </div>
      ) : (
        <>
          <div className='absolute top-4 right-3'>
            <IconButton onClick={onError} style={{ paddingTop: '0' }}>
              <Close />
            </IconButton>
          </div>
          <SearchError />
        </>
      )}
    </div>
  )
}

export default SendData
