import { Application, DatePicker } from 'react-rainbow-components'
import { RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material'
import Button from '../../commons/Button'
import { useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import ProfilePicture from '../../commons/ProfilePicture'
import EditPicture from '../ProfileInfo/EditPicture'

export default ({ setStep, picture, setPicture, setProfileImg }) => {
  const [pictureEdited, setPictureEdited] = useState(false)

  return (
    <div className='h-full flex flex-col px-6 py-6 justify-between'>
      <div>
        <div className='flex justify-between items-center mb-6'>
          <div onClick={() => setStep(3)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='text-2xl text-primary cursor-pointer'
            />
          </div>
          <div className='text-base text-center font-bold text-primary'>Paso 4/5</div>
          <div />
        </div>
        <p className='text-lg text-primary mb-4 text-center'>
          Impulsá tus viajes un 75% con una foto de perfil
        </p>
        <p className='text-sm text-gray mb-4 text-center'>
          Las personas que dispongan de su foto aumentan sus chances de conseguir viaje
        </p>
      </div>
      <div>
        <ProfilePicture
          src={picture}
          setImage={setPicture}
          setPictureEdited={setPictureEdited}
          text='SELECCIONAR FOTO'
          size={150}
        />
      </div>
      <div className='flex items-center justify-center w-full'>
        <Button
          name='Siguiente'
          onClick={() => {
            Mixpanel.track('Boton siguiente', {
              Picture: picture
            })
            setStep(5)
          }}
          className='w-full md:w-[150px]'
        />
      </div>
      {pictureEdited ? (
        <EditPicture
          visible={pictureEdited}
          setVisible={setPictureEdited}
          picture={picture}
          setAvatarImage={setPicture}
          setProfileImg={setProfileImg}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
