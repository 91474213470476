import Button from '../../commons/Button'
import SlideModal from '../../commons/SlideModal'
import { useLocalizationContext } from '../../contexts/LocalizationContext'
import { useAuth } from '../../hooks'
import { Mixpanel } from '../../Mixpanel'

export default ({ setVisible, type }) => {
  const { config } = useLocalizationContext()
  const { loginWithRedirect, logout } = useAuth()

  const handleLink = () => {
    Mixpanel.track('Telefono repetido - Contactar soporte')
    window.open(
      `https://api.whatsapp.com/send/?phone=${config.phone_number}&text=Hola, tengo un problema con mi número de teléfono`,
      '_blank'
    )
  }

  const handleSubmit = async () => {
    Mixpanel.track('Telefono repetido - Cerrar sesion')
    logout()
    loginWithRedirect({ screen_hint: 'login', connection: type })
  }

  return (
    <SlideModal open={true} onClose={() => setVisible(false)}>
      <div className='flex flex-col justify-between h-full px-6 py-6'>
        <div>
          <p className='text-lg text-primary mb-3'>Cuenta existente</p>
          <p className='text-base mb-6'>
            Parece que este número de teléfono ya lo estas usando en otra cuenta. Por favor, iniciá
            sesión {typeText(type)}.
          </p>
        </div>
        <ConnectionButton type={type} handleSubmit={handleSubmit} />
        <p
          onClick={handleLink}
          className='text-sm text-light_coral cursor-pointer mt-4 text-center'
        >
          No tengo otra cuenta
        </p>
      </div>
    </SlideModal>
  )
}

const ConnectionButton = ({ type, handleSubmit }) => {
  return (
    <div
      onClick={handleSubmit}
      className='flex w-full items-center px-4 h-[50px] border-[0.5px] border-gray rounded-[10px]'
    >
      <img src={typeImage(type)} className='w-5 h-5' />
      <p className='text-base font-thin ml-3'>Continuar {typeText(type)}</p>
    </div>
  )
}

const typeText = type => {
  if (type === 'Username-Password-Authentication') {
    return 'con Email'
  } else if (type === 'google-oauth2') {
    return 'con Google'
  } else if (type === 'facebook') {
    return 'con Facebook'
  } else if (type === 'apple') {
    return 'con Apple'
  } else {
    return 'con tu cuenta original'
  }
}

const typeImage = type => {
  if (type === 'google-oauth2') {
    return require('../../assets/img/google.png')
  } else if (type === 'facebook') {
    return require('../../assets/img/facebook.png')
  } else if (type === 'apple') {
    return require('../../assets/img/apple_logo.png')
  } else {
    return require('../../assets/img/email.png')
  }
}
