export const config = {
  uy: {
    phone_number: '+59898415699',
    documentType: {
      long_name: 'Cédula de Identidad',
      short_name: 'CI'
    },
    schedule: {
      text: 'Aún no hay viajes compartidos para el día seleccionado.',
      button: 'Notificarme'
    },
    location_example: {
      origin: 'Ej: Pocitos',
      destination: 'Ej: Maldonado'
    }
  },
  ar: {
    phone_number: '+5491128620965',
    documentType: {
      long_name: 'DNI',
      short_name: 'DNI'
    },
    schedule: {
      text: 'Aún no hay viajes compartidos para el día seleccionado, pero podemos encontrarlo por vos.',
      button: 'Encontrarme un viaje'
    },
    location_example: {
      origin: 'Ej: Belgrano',
      destination: 'Ej: Mar del Plata'
    }
  }
}
