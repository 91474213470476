import { useEffect, useState } from 'react'
import SlideModal from '../../commons/SlideModal'
import { TextField } from '@mui/material'
import Button from '../../commons/Button'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { Mixpanel } from '../../Mixpanel'

export const ReportModal = ({ visible, setVisible, selectedChannel }) => {
  const [reason, setReason] = useState('')
  const [report, setReport] = useState(false)
  const [reportAndBlock, setReportAndBlock] = useState(false)
  const blockedUser = selectedChannel?.users.find(u => u.blocked)

  const handleSubmit = block => {
    if (block) {
      setReportAndBlock(true)
    } else {
      setReport(true)
    }
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleSuccess = () => {
    Mixpanel.track('Usuario reportado', {
      Chat: selectedChannel,
      Bloqueado: reportAndBlock
    })
    setVisible(false)
  }

  const handleError = () => {
    Mixpanel.track('Error reportando usuario', {
      Chat: selectedChannel,
      Bloqueado: reportAndBlock
    })
    setReport(false)
    setReportAndBlock(false)
  }

  return (
    <SlideModal open={visible} onClose={handleClose} onSubmit={handleSubmit}>
      <div className='flex flex-col w-full p-6'>
        <p className='mb-2 text-lg'>Reportar usuario</p>
        <p className='mb-2 text-sm text-gray mb-4'>
          Por favor, cuéntanos brevemente la razón de tu reporte. Esto nos ayudará a investigar y
          tomar las medidas adecuadas.
        </p>
        <TextField
          id='reason'
          multiline
          maxRows={4}
          onChange={evt => setReason(evt.target.value)}
          placeholder='Escribí aquí la razón de tu reporte...'
          minRows={4}
          value={reason}
          inputProps={{
            maxLength: 180
          }}
          autoFocus
          sx={{ marginBottom: '1.5rem' }}
        />
        {report ? (
          <div className='mb-[1rem]'>
            <Request
              path={'report/' + selectedChannel._id}
              body={{ reason }}
              handleSuccess={handleSuccess}
              handleError={handleError}
            />
          </div>
        ) : (
          <Button
            onClick={() => handleSubmit(false)}
            disabled={!reason.trim() || reportAndBlock}
            name={'Enviar reporte'}
            style={{ fontSize: '0.8rem', marginBottom: blockedUser ? 0 : '1rem' }}
          />
        )}
        {!blockedUser && (
          <>
            {reportAndBlock ? (
              <Request
                path={'report/' + selectedChannel._id}
                body={{ reason, block: true }}
                handleSuccess={handleSuccess}
                handleError={handleError}
              />
            ) : (
              <Button
                onClick={() => handleSubmit(true)}
                disabled={!reason.trim() || report}
                color='#ef4444'
                style={{ fontSize: '0.8rem' }}
                name={'Enviar reporte y bloquear'}
              />
            )}
          </>
        )}
      </div>
    </SlideModal>
  )
}

export const BlockModal = ({ visible, setVisible, selectedChannel }) => {
  const [block, setBlock] = useState(false)

  const handleSubmit = () => {
    setBlock(true)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleSuccess = () => {
    Mixpanel.track('Usuario bloqueado', {
      Chat: selectedChannel
    })
    setVisible(false)
  }

  const handleError = () => {
    Mixpanel.track('Error bloqueando usuario', {
      Chat: selectedChannel
    })
    setBlock(false)
  }

  return (
    <SlideModal open={visible} onClose={handleClose}>
      <div className='flex flex-col w-full p-6'>
        <p className='mb-2 text-lg'>Bloquear usuario</p>
        <p className='mb-2 text-sm text-gray mb-4'>
          ¿Estás seguro que deseas bloquear a este usuario? No podrás enviar ni recibir mensajes de
          él.
        </p>
        {block ? (
          <Request
            path={'block/' + selectedChannel._id}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        ) : (
          <Button onClick={handleSubmit} name={'Confirmar'} style={{ fontSize: '0.8rem' }} />
        )}
      </div>
    </SlideModal>
  )
}

const Request = ({ path, body, handleSuccess, handleError }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/channels/${path}`, {
    method: 'POST',
    body: JSON.stringify(body)
  })

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        if (data.blocked_user) {
          window.location.reload()
        }
        handleSuccess()
      })
    } else if (!loading) {
      handleError()
    }
  }, [loading])

  return (
    <div className='flex w-full items-center justify-center'>
      <LoadingIndicator />
    </div>
  )
}
