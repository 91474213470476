import { useAuth } from '../'
import { useState, useEffect } from 'react'
import auth0Config from '../../config/Auth0'
import { useLocalizationContext } from '../../contexts/LocalizationContext'

const useFetch = (url, fetchInfo) => {
  const { userCountry, loading: loadingCountry } = useLocalizationContext()
  const [loading, setLoading] = useState(true)
  const [responseOk, setResponseOk] = useState(false)
  const [response, setResponse] = useState({})
  const [data, setData] = useState([])
  const { isAuthenticated, getAccessTokenSilently } = useAuth()

  useEffect(() => {
    setLoading(true)
    setData([])
    setResponseOk(false)
    const fetchData = async token => {
      fetch(url, {
        ...fetchInfo,
        headers: {
          'Content-Type': 'application/json',
          'authorization': token ? 'Bearer ' + token : '',
          'X-Country': userCountry
        }
      })
        .then(res => {
          if (res.ok) setResponseOk(true)
          setResponse(res)
          return res.json()
        })
        .then(data => {
          setData(data)
          setLoading(false)
        })
        .catch(e => {
          console.log(e)
          setData(e)
          setLoading(false)
        })
    }

    if (loadingCountry) return
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: auth0Config.audience
      }).then(token => fetchData(token))
    } else {
      fetchData()
    }
  }, [url, loadingCountry])

  return { loading, data, responseOk, response }
}

export default useFetch
