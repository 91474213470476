import { TextField } from '@mui/material'
import Button from './Button'
import Preferences from '../../commons/Preferences'
import { useState } from 'react'
import { containsContactInfo } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const ThirdStep = ({
  onSubmit,
  setAboutMe,
  aboutMe,
  preferences,
  setPreferences,
  date,
  gender,
  setStep
}) => {
  const [pictureEdited, setPictureEdited] = useState(false)
  const age = (new Date().getTime() - date.getTime()) / (365 * 24 * 60 * 60 * 1000)
  const [biographyError, setBiographyError] = useState(false)

  const handleSubmit = () => {
    if (containsContactInfo(aboutMe)) {
      setBiographyError(true)
    } else {
      setBiographyError(false)
      onSubmit()
    }
  }

  return (
    <div className='h-full flex flex-col px-6 py-6 justify-between'>
      <div className='flex flex-col md:items-center'>
        <div className='flex md:w-full justify-between items-center mb-6'>
          <div onClick={() => setStep(4)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='text-2xl text-primary cursor-pointer'
            />
          </div>
          <div className='text-base text-center font-bold text-primary'>Paso 5/5</div>
          <div />
        </div>
        <div className='text-lg text-primary mb-6'>Contanos más sobre vos</div>
        <div className='mb-8'>
          <p className='text-lg font-thin text-primary mb-2'>Sobre mí</p>
          <TextField
            fullWidth
            multiline
            maxRows={3}
            onChange={evt => {
              if (evt.target.value.length >= 180) {
                setAboutMe(aboutMe)
              } else {
                setAboutMe(evt.target.value)
              }
            }}
            placeholder={
              age < 25
                ? 'Ejemplo: Soy estudiante de medicina, me gusta el fútbol y viajar'
                : age >= 25 && age < 40
                ? 'Ejemplo: Trabajo en una empresa de tecnología, me gusta el rock y el fútbol'
                : gender == 'Hombre'
                ? 'Ejemplo: Soy padre de familia, viajo para trabajar y visitar a mis hijos'
                : 'Ejemplo: Trabajo en una empresa de tecnología, viajo para visitar a mi familia'
            }
            minRows={3}
            value={aboutMe}
          />
          {biographyError && (
            <p className='text-xs text-[#ef4444] px-3 pt-1'>
              No puede contener información de contacto
            </p>
          )}
        </div>
        <p className='text-lg font-thin text-primary mb-2'>Preferencias al viajar</p>
        <Preferences preferences={preferences} setPreferences={setPreferences} gender={gender} />
      </div>
      <div className='flex flex-col items-center'>
        <Button name='Confirmar' onClick={handleSubmit} />
        <p className='text-xs text-center mt-4'>Al confirmar aceptas nuestros</p>
        <a
          href='https://viatik.com/terminos'
          className='text-light_coral text-xs text-center self-center cursor-pointer hover:text-light_coral'
          target='_blank'
        >
          términos y condiciones
        </a>
      </div>
    </div>
  )
}

export default ThirdStep
