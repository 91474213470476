import Button from './Button'
import { Mixpanel } from '../../Mixpanel'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

export default ({ initialValues, name, lastName, setName, setLastName, setStep }) => {
  useEffect(() => {
    if (initialValues.name && name === '') {
      setName(initialValues.name)
    }
    if (initialValues.lastName && lastName === '') {
      setLastName(initialValues.lastName)
    }
  }, [initialValues])

  return (
    <div className='h-full flex flex-col px-6 py-6 justify-between'>
      <div className='flex flex-col md:items-center'>
        <div className='flex md:w-full justify-between items-center mb-6'>
          <div onClick={() => setStep(1)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='text-2xl text-primary cursor-pointer'
            />
          </div>
          <div className='text-base text-center font-bold text-primary'>Paso 2/5</div>
          <div />
        </div>
        <div className='text-lg text-primary mb-6'>
          Necesitamos los siguientes datos para continuar
        </div>
        <input
          name='name'
          value={name}
          onChange={e => {
            if (e.target.value.length <= 20) setName(e.target.value)
          }}
          placeholder='Nombre'
          className='w-full bg-light_gray rounded-[10px] p-4 mt-4 mb-8 max-w-[600px]'
        />
        <input
          name='lastName'
          value={lastName}
          onChange={e => {
            if (e.target.value.length <= 20) setLastName(e.target.value)
          }}
          placeholder='Apellido'
          className='w-full bg-light_gray rounded-[10px] p-4 mt-4 max-w-[600px]'
        />
      </div>
      <Button
        name='Siguiente'
        disabled={name.trim() === '' || lastName.trim() === ''}
        onClick={() => {
          Mixpanel.track('Boton siguiente', {
            Nombre: name,
            Apellido: lastName
          })
          setStep(3)
        }}
      />
    </div>
  )
}
