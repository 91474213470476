import { useState } from 'react'
import RequestsHandler from './RequestsHandler'
import { useFetch, useAuthorizedFetch } from '../../hooks'
import { getLobbiesByDate } from '../../utils'
import { uri } from '../../config/Api'
import Tabs from './Tabs'
import CardsList from '../../commons/CardsList'
import TimeTravelButton from '../TimeTravelButton'
import ScheduleButton from '../ScheduleButton'
import Empty from '../Empty'
import BlockedRoute from '../BlockedRoute'
import { Mixpanel } from '../../Mixpanel'

const LobbiesAndBusesList = ({
  dataToFetchLobbies,
  dataToFetchBuses,
  setPastLobbies,
  setFutureLobbies,
  setScheduleModal,
  searchData,
  handleLobbyCardPress,
  handleBusCardPress
}) => {
  const lobbiesRequest = useFetch(`${uri}/lobbies?${new URLSearchParams(dataToFetchLobbies)}`)
  const busesRequest = useAuthorizedFetch(`${uri}/buses?${new URLSearchParams(dataToFetchBuses)}`)
  const [lobbies, setLobbies] = useState({})
  const [buses, setBuses] = useState({})
  const [lobbiesAndBuses, setLobbiesAndBuses] = useState({})
  const [empty, setEmpty] = useState()
  const [selectedTab, setSelectedTab] = useState('lobbiesAndBuses')
  const [lobbiesAndBusesLength, setLobbiesAndBusesLength] = useState(0)
  const [lobbiesLength, setLobbiesLength] = useState(0)
  const [busesLength, setBusesLength] = useState(0)
  const [blockedRoute, setBlockedRoute] = useState(false)

  const handleChange = (event, selected) => {
    Mixpanel.track('Cambio tab', { Tab: selected })
    setSelectedTab(selected)
  }

  const handleSuccess = async (lobbies, buses) => {
    const { lobbiesAndBusesByDate, lobbiesByDate, busesByDate } = getLobbiesByDate(lobbies, buses)
    setEmpty(Object.keys(lobbiesAndBusesByDate).length == 0)
    setLobbiesAndBuses(lobbiesAndBusesByDate)
    setLobbies(lobbiesByDate)
    setBuses(busesByDate)
    setLobbiesAndBusesLength(getLength(lobbiesAndBusesByDate))
    setLobbiesLength(getLength(lobbiesByDate))
    setBusesLength(getLength(busesByDate))
  }

  const handleBlockedRoute = content => {
    Mixpanel.track('Ruta bloqueada', {
      Origen: searchData.originName.formatted_address,
      Destino: searchData.destinationName.formatted_address,
      Fecha: searchData.selectedDate
    })
    setBlockedRoute(content)
  }

  return (
    <RequestsHandler
      lobbiesRequest={lobbiesRequest}
      busesRequest={busesRequest}
      handleSuccess={handleSuccess}
      handleBlockedRoute={handleBlockedRoute}
    >
      {blockedRoute ? (
        <BlockedRoute
          content={blockedRoute}
          searchData={{
            originName: searchData.originName.formatted_address,
            destinationName: searchData.destinationName.formatted_address
          }}
          type={'Buscar'}
        />
      ) : empty === true ? (
        <Empty
          searchData={searchData}
          fetchData={dataToFetchLobbies}
          setPastLobbies={setPastLobbies}
          setFutureLobbies={setFutureLobbies}
        />
      ) : (
        empty === false && (
          <div className='h-full overflow-auto pb-48'>
            <CardsList
              items={lobbiesAndBuses}
              handleLobbyCardPress={handleLobbyCardPress}
              handleBusCardPress={handleBusCardPress}
            />
            <BottomButtons
              fetchData={dataToFetchLobbies}
              setPastLobbies={setPastLobbies}
              setFutureLobbies={setFutureLobbies}
              setScheduleModal={setScheduleModal}
            />
          </div>
        )
      )}
    </RequestsHandler>
  )
}

const getLength = list => {
  let length = 0
  Object.keys(list).forEach(key => {
    length += list[key].length
  })
  return length
}

const BottomButtons = ({ fetchData, setPastLobbies, setFutureLobbies, setScheduleModal }) => {
  return (
    <>
      <ScheduleButton setScheduleModal={setScheduleModal} />
      <TimeTravelButton
        type='PastLobbiesList'
        fetchData={fetchData}
        setTimeTravelLobbies={setPastLobbies}
      />
      <TimeTravelButton
        type='FutureLobbiesList'
        fetchData={fetchData}
        setTimeTravelLobbies={setFutureLobbies}
      />
    </>
  )
}

export default LobbiesAndBusesList
