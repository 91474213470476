import { Application, DatePicker } from 'react-rainbow-components'
import { RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material'
import Button from './Button'
import { useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default ({ setStep, selectedDate, setSelectedDate, gender, setGender }) => {
  const [selection, setSelection] = useState(
    gender != 'Mujer' && gender != 'Hombre' ? 'Otro' : gender
  )
  const maxDate = new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)

  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: '#f25c45'
      }
    }
  }

  return (
    <div className='h-full flex flex-col px-6 py-6 justify-between'>
      <div className='flex flex-col md:items-center'>
        <div className='flex md:w-full justify-between items-center mb-6'>
          <div onClick={() => setStep(2)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='text-2xl text-primary cursor-pointer'
            />
          </div>
          <div className='text-base text-center font-bold text-primary'>Paso 3/5</div>
          <div />
        </div>
        <div className='text-lg text-primary mb-6'>
          Necesitamos los siguientes datos para continuar
        </div>
        <div className='mb-8'>
          <p className='text-lg font-thin text-primary mb-2'>Fecha de nacimiento</p>
          <Application theme={rainbowTheme}>
            <DatePicker
              value={selectedDate}
              maxDate={maxDate}
              onChange={setSelectedDate}
              label='Fecha de nacimiento'
              formatStyle='medium'
              placeholder={'Seleccionar fecha'}
              borderRadius='semi-rounded'
              hideLabel
              selectionType='single'
            />
          </Application>
        </div>
        <p className='text-lg font-thin text-primary mb-2'>Género</p>
        <FormControl>
          <RadioGroup
            row
            value={selection}
            onChange={evt => {
              setGender(evt.target.value)
              setSelection(evt.target.value)
            }}
          >
            <FormControlLabel value='Mujer' control={<Radio />} label='Mujer' />
            <FormControlLabel value='Hombre' control={<Radio />} label='Hombre' />
            <FormControlLabel value='Otro' control={<Radio />} label='Otro' />
          </RadioGroup>
        </FormControl>
        {selection === 'Otro' && (
          <input
            placeholder='Especificar (opcional)'
            className='w-full bg-light_gray rounded-[10px] p-4 mt-4'
            value={gender == 'Otro' ? '' : gender}
            onChange={e => {
              setGender(e.target.value)
            }}
          />
        )}
      </div>
      <Button
        name='Siguiente'
        onClick={() => {
          Mixpanel.track('Boton siguiente', {
            Fecha: selectedDate,
            Genero: gender
          })
          setStep(4)
        }}
      />
    </div>
  )
}
