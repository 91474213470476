import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import SendData from './SendData'
import { Mixpanel } from '../../Mixpanel'
import NameStep from './NameStep'
import BirthdateStep from './BirthdateStep'
import AboutStep from './AboutStep'
import { useAuth } from '../../hooks/'
import { namespace } from '../../config/Auth0'
import styles from './styles'
import PhoneStep from './PhoneStep'
import PictureStep from './PictureStep'

const Register = ({ visible, onSubmit, setProfileImg }) => {
  const { isAuthenticated, user } = useAuth()
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
  )
  const [gender, setGender] = useState('Mujer')
  const [picture, setPicture] = useState(user?.picture)
  const [aboutMe, setAboutMe] = useState('')
  const [preferences, setPreferences] = useState([])
  const [step, setStep] = useState(1)
  const [sendData, setSendData] = useState(false)

  let initialValues = {
    name: name,
    last_name: lastName,
    phone_number: phoneNumber
  }

  useEffect(() => {
    if (isAuthenticated && user[namespace + 'phone_number']) {
      initialValues.name = user[namespace + 'first_name']
      setName(user[namespace + 'first_name'])
      initialValues.last_name = user[namespace + 'last_name']
      setLastName(user[namespace + 'last_name'])
      initialValues.phone_number = user[namespace + 'phone_number']
      setPhoneNumber(user[namespace + 'phone_number'])
    } else if (isAuthenticated) {
      if (user.given_name) {
        initialValues.name = user.given_name
        setName(user.given_name)
      }
      if (user.family_name) {
        initialValues.last_name = user.family_name
        setLastName(user.family_name)
      }
    }
  }, [])

  const handleSubmit = () => {
    setSendData(true)
    Mixpanel.track('Boton Confirmar registro', {
      Nombre: name,
      Imagen: user.picture,
      Biografia: aboutMe,
      Preferencias: preferences,
      Usuario: user
    })
  }

  return (
    <Modal isOpen={visible} style={styles} contentLabel='Register'>
      {sendData ? (
        <SendData
          data={{ name, lastName, phoneNumber, selectedDate, gender, aboutMe, preferences }}
          onSubmit={onSubmit}
          onError={() => setSendData(false)}
        />
      ) : step === 1 ? (
        <PhoneStep
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onSubmit={() => setStep(2)}
        />
      ) : step === 2 ? (
        <NameStep
          initialValues={initialValues}
          name={name}
          lastName={lastName}
          setName={setName}
          setLastName={setLastName}
          setStep={setStep}
        />
      ) : step === 3 ? (
        <BirthdateStep
          setStep={setStep}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          gender={gender}
          setGender={setGender}
        />
      ) : step === 4 ? (
        <PictureStep
          setStep={setStep}
          picture={picture}
          setPicture={setPicture}
          setProfileImg={setProfileImg}
        />
      ) : (
        <AboutStep
          aboutMe={aboutMe}
          setAboutMe={setAboutMe}
          onSubmit={handleSubmit}
          preferences={preferences}
          setPreferences={setPreferences}
          date={new Date(selectedDate)}
          gender={gender}
          setStep={setStep}
        />
      )}
    </Modal>
  )
}

export default Register
