import { useAuth } from '../'
import { useState, useEffect } from 'react'
import auth0Config from '../../config/Auth0'
import { useLocalizationContext } from '../../contexts/LocalizationContext'

const useAuthorizedFetch = (url, fetchInfo) => {
  const { userCountry, loading: loadingCountry } = useLocalizationContext()
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState([])
  const [responseOk, setResponseOk] = useState(false)
  const { getAccessTokenSilently } = useAuth()

  const fetchData = async token => {
    fetch(url, {
      ...fetchInfo,
      headers: {
        'Content-Type': 'application/json',
        'authorization': token ? 'Bearer ' + token : '',
        'X-Country': userCountry
      }
    })
      .then(async response => {
        setResponseOk(response.ok)
        setResponse(response)
        setLoading(false)
      })
      .catch(err => {
        setResponse(err)
        setLoading(false)
      })
  }
  useEffect(() => {
    if (loadingCountry) return
    getAccessTokenSilently({
      audience: auth0Config.audience
    })
      .then(token => fetchData(token))
      .catch(e => {
        fetchData()
        setResponse('Error de autenticacion')
      })
  }, [loadingCountry])
  return { loading, response, responseOk }
}

export default useAuthorizedFetch
