import Button from '../../commons/Button'
import { useState } from 'react'
import Lottie from 'lottie-react'
import { Mixpanel } from '../../Mixpanel'

export default ({ searchData, content, type }) => {
  const [pressed, setPressed] = useState(false)

  const handleSubmit = () => {
    Mixpanel.track(`Botón avisarme ruta bloqueada - ${type}`, {
      Origen: searchData.originName,
      Destino: searchData.destinationName
    })
    setPressed(true)
  }

  return (
    <>
      <div className='flex flex-col h-[85%] items-center justify-center px-6'>
        <img
          src={require('../../assets/img/empty.png')}
          className='w-[240px] h-[160px] narrow:w-[270px] narrow:h-[180px]'
        />
        <p className='text-center text-[18px] font-semibold mb-5 mt-5'>{content.title}</p>
        <p className='text-center text-[16px] mb-6 font-thin'>{content.text}</p>
        {pressed ? (
          <Lottie
            animationData={require('../../assets/animations/success.json')}
            autoplay
            loop={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            style={{ width: 60, height: 60 }}
          />
        ) : (
          <div className='h-[60px] w-full md:w-1/4'>
            <Button onClick={handleSubmit} name={'Avisarme'} className='w-full' />
          </div>
        )}
      </div>
    </>
  )
}
