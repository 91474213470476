import { useState } from 'react'
import Button from '../../commons/Button'
import ScheduleAlert from '../ScheduleAlert'
import TimeTravelButton from '../TimeTravelButton'
import { useAuth } from '../../hooks'
import { config } from '../../config/Localization'
import { getCountry } from '../../utils'

const Empty = ({ searchData, fetchData, setPastLobbies, setFutureLobbies }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth()
  const [scheduleAlert, setScheduleAlert] = useState(false)
  const country = getCountry(searchData)

  const handleSubmit = () => {
    if (isAuthenticated) {
      setScheduleAlert(true)
    } else {
      loginWithRedirect({
        appState: `${window.location.origin}/?navigation=SearchLobby&searchData=${JSON.stringify(
          searchData
        )}&schedule=true`
      })
    }
  }

  return (
    <div
      className='flex flex-col text-lg text-lg overflow-auto sm:justify-center pb-20'
      style={{ height: 'calc(100vh - 120px)' }}
    >
      <TimeTravelButton
        type='PastLobbiesList'
        fetchData={fetchData}
        setTimeTravelLobbies={setPastLobbies}
      />
      <div className='bg-[#f3f4f6] w-full mt-2 mb-2 h-3' />
      <div className='flex flex-col justify-center items-center px-1'>
        <img className='w-60' src={require('../../assets/img/empty.png')} />
        <div className='items-center pl-5 pr-5'>
          <p className='mt-3 mb-6 text-[#9ca3af] text-base'>{config[country].schedule.text}</p>
          {scheduleAlert ? (
            <ScheduleAlert searchData={searchData} />
          ) : (
            <Button name={config[country].schedule.button} onClick={handleSubmit} />
          )}
        </div>
      </div>
      <div className='bg-[#f3f4f6] w-full mt-6 mb-2 h-3' />
      <TimeTravelButton
        type='FutureLobbiesList'
        fetchData={fetchData}
        setTimeTravelLobbies={setFutureLobbies}
      />
    </div>
  )
}

export default Empty
