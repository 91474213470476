export default searchData => {
  if (
    searchData &&
    searchData.originName.country_code === 'AR' &&
    searchData.destinationName.country_code === 'AR'
  ) {
    return 'ar'
  } else {
    return 'uy'
  }
}
