import Button from './Button'
import { Mixpanel } from '../../Mixpanel'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useEffect, useState } from 'react'
import { useLocalizationContext } from '../../contexts/LocalizationContext'
import { useAuth, useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { Snackbar, Alert } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import DuplicatePhone from './DuplicatePhone'

export default ({ phoneNumber, setPhoneNumber, onSubmit }) => {
  const { logout } = useAuth()
  const { userCountry } = useLocalizationContext()
  const [error, setError] = useState(false)
  const [checkExists, setCheckExists] = useState(false)
  const [duplicatePhone, setDuplicatePhone] = useState(false)

  const handleSubmit = () => {
    if (isValidPhoneNumber(phoneNumber)) {
      setError(false)
      let formattedPhoneNumber = formatPhoneNumber(phoneNumber)
      setCheckExists(formattedPhoneNumber)
    } else {
      setError(true)
    }
  }

  const handleNext = data => {
    if (!data.exists) {
      Mixpanel.track('Boton siguiente', {
        Telefono: phoneNumber
      })
      setPhoneNumber(checkExists)
      onSubmit()
      setError(false)
    } else {
      Mixpanel.track('Error telefono repetido', {
        Telefono: phoneNumber
      })
      setDuplicatePhone(data.connection)
    }
    setCheckExists(false)
  }

  const handleLogout = () => {
    Mixpanel.track('Boton Cerrar Sesion')
    logout({ returnTo: window.origin })
  }

  return (
    <div className='h-full flex flex-col px-6 py-6 justify-between'>
      <div>
        <div className='flex justify-between items-center mb-6'>
          <div onClick={logout}>
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className='text-2xl text-primary cursor-pointer'
            />
          </div>
          <div className='text-base text-center font-bold text-primary'>Paso 1/5</div>
          <div />
        </div>
        <div className='text-lg text-primary mb-6'>
          Necesitamos los siguientes datos para continuar
        </div>
        <PhoneInput
          international
          placeholder='Teléfono'
          onChange={setPhoneNumber}
          value={phoneNumber}
          defaultCountry={userCountry?.toUpperCase()}
          name='phone_number'
          className='rounded-md w-[100%] p-1 bg-light_gray py-4 px-4 text-base mb-4 hover:border-0'
        />
        {error && <p className='text-error text-sm'>Número de teléfono invalido</p>}
      </div>
      {checkExists ? (
        <VerifyPhoneNumber
          phoneNumber={checkExists}
          next={handleNext}
          setCheckExists={setCheckExists}
        />
      ) : (
        <Button onClick={handleSubmit} name={'Siguiente'} />
      )}
      {duplicatePhone && <DuplicatePhone setVisible={setDuplicatePhone} type={duplicatePhone} />}
    </div>
  )
}

const VerifyPhoneNumber = ({ phoneNumber, next, setCheckExists }) => {
  const [error, setError] = useState(false)
  const { loading, response } = useAuthorizedFetch(
    `${uri}/users/exists?${new URLSearchParams({ phone_number: phoneNumber })}`,
    { method: 'POST' }
  )

  const handleSuccess = data => {
    next(data)
  }

  const handleError = () => {
    if (response.status === 429) {
      setError('Demasiados intentos, intenta más tarde')
    } else {
      setError('Error al verificar el número de teléfono')
    }
    setTimeout(() => setCheckExists(false), 2000)
  }

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => handleSuccess(data))
    } else if (!loading) {
      handleError()
    }
  }, [loading])

  return (
    <>
      <div className='flex items-center justify-center'>
        <LoadingIndicator />
      </div>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ marginBottom: 20 }}
        >
          <Alert
            onClose={() => {
              setError(false)
              setCheckExists(false)
            }}
            severity='error'
            variant='filled'
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

const formatPhoneNumber = phoneNumber => {
  return phoneNumber.substring(0, 4) === '+598' && phoneNumber.charAt(4) === '0'
    ? phoneNumber.substring(0, 4) + phoneNumber.substring(5)
    : phoneNumber
}
